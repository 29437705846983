<template>
	<div>
		<a-descriptions
			title="Informações da empresa"
			:column="8"
			layout="vertical"
			class="descriptions"
		>
			<a-descriptions-item label="CNPJ" :span="2">
				{{ company.cnpj }}
			</a-descriptions-item>
			<a-descriptions-item label="Razão Social" :span="2">
				{{ company.company_name }}
			</a-descriptions-item>
			<a-descriptions-item label="Nome de Fantasia" :span="2">
				{{ company.fantasy_name }}
			</a-descriptions-item>
			<a-descriptions-item label="Tipo">
				{{ company.type }}
			</a-descriptions-item>
			<a-descriptions-item label="Telefone">
				{{ company.phone }}
			</a-descriptions-item>
		</a-descriptions>
		<a-divider />
		<a-descriptions
			title="Endereço da empresa"
			:column="8"
			layout="vertical"
			class="descriptions"
		>
			<a-descriptions-item label="Nome da rua" :span="3">
				{{ address.street_name }}
			</a-descriptions-item>
			<a-descriptions-item label="Número">
				{{ address.street_number }}
			</a-descriptions-item>
			<a-descriptions-item label="Complemento" :span="4">
				{{ address.complement }}
			</a-descriptions-item>
			<a-descriptions-item label="Bairro" :span="2">
				{{ address.neighborhood }}
			</a-descriptions-item>
			<a-descriptions-item label="Cidade" :span="2">
				{{ address.city }}
			</a-descriptions-item>
			<a-descriptions-item label="Estado" :span="1">
				{{ address.state }}
			</a-descriptions-item>
			<a-descriptions-item label="País" :span="1">
				{{ countryName }}
			</a-descriptions-item>
			<a-descriptions-item label="CEP" :span="2">
				{{ address.postal_code }}
			</a-descriptions-item>
		</a-descriptions>
		<a-divider />
		<a-descriptions
			title="Informações bancárias"
			:column="8"
			layout="vertical"
			class="descriptions"
		>
			<a-descriptions-item label="Banco" :span="2">
				{{ bankName }}
			</a-descriptions-item>
			<a-descriptions-item label="Agência" :span="1">
				{{ bank_account.agency }}
			</a-descriptions-item>
			<a-descriptions-item label="Número da conta" :span="2">
				{{ bank_account.account }}
			</a-descriptions-item>
			<a-descriptions-item label="Tipo de conta" :span="2">
				{{ banckAccountType }}
			</a-descriptions-item>
			<a-descriptions-item label="É uma conta PJ?">
				{{ isJuridic }}
			</a-descriptions-item>
		</a-descriptions>
		<a-divider />
		<a-descriptions
			v-if="contract"
			title="Informações contratuais"
			:column="8"
			layout="vertical"
			class="descriptions"
		>
			<a-descriptions-item label="Abertura do contrato" :span="2">
				{{ moment(contract.begin_date).format('DD/MM/YYYY') }}
			</a-descriptions-item>
			<a-descriptions-item label="Término do contrato" :span="2">
				{{ moment(contract.end_date).format('DD/MM/YYYY') }}
			</a-descriptions-item>
			<a-descriptions-item label="Dia de abertura da Fatura" :span="2">
				{{ formatDay(contract.invoice_opening_day) }}
			</a-descriptions-item>
			<a-descriptions-item label="Dia de vencimento" :span="2">
				{{ formatDay(contract.invoice_due_day) }}
			</a-descriptions-item>
			<a-descriptions-item label="Método de cobrança" :span="2">
				{{ feeType }}
			</a-descriptions-item>
			<a-descriptions-item label="Valor cobrado por pedido" :span="2">
				{{ feeValue }}
			</a-descriptions-item>
			<a-descriptions-item label="Valor por pedido cancelado" :span="2">
				{{ cancelFeeValue }}
			</a-descriptions-item>
		</a-descriptions>
		<a
			v-if="contract"
			class="doc-button"
			:href="contract.doc"
			target="_blank"
			download
		>
			BAIXAR CONTRATO
		</a>
	</div>
</template>

<script>
import { stateList } from '@/assets/emitterStateList'
import { axios } from '@/utils/axios'
import notification from 'ant-design-vue/es/notification'
import { mapActions, mapGetters } from 'vuex'

export default {
	name: '',
	data() {
		return {
			stateList,
			banks: [],
			company: {},
			contract: null,
		}
	},
	computed: {
		...mapGetters({
			selectedMerchantId: 'merchants/selectedMerchantId',
		}),
		bank_account: function () {
			return this.company.bank_account || {}
		},
		address: function () {
			return this.company.address || {}
		},
		countryName: function () {
			const names = {
				BR: 'Brasil',
			}

			return names[this.address.country]
		},
		bankName: function () {
			const bank = this.banks.find(
				(bank) => bank.code === this.bank_account.bank
			)
			return bank ? bank.label : ''
		},
		banckAccountType: function () {
			return this.bank_account.is_saving
				? 'Conta Poupança'
				: 'Conta Corrente'
		},
		isJuridic: function () {
			return this.bank_account.is_juridic ? 'SIM' : 'NÃO'
		},
		feeType: function () {
			const types = {
				ABS: 'Valor Fixo',
				PCT: 'Valor Percentual',
			}

			return this.contract ? types[this.contract.business_model] : ''
		},
		feeValue: function () {
			if (this.contract) {
				const { value, business_model, currency } = this.contract

				switch (business_model) {
					default:
					case 'ABS':
						return new Intl.NumberFormat('pt-BR', {
							style: 'currency',
							currency,
						}).format(value)
					case 'PCT':
						return `${value} %`
				}
			} else return ''
		},
		cancelFeeValue: function () {
			if (this.contract) {
				const { cancellation_fee, currency } = this.contract

				return new Intl.NumberFormat('pt-BR', {
					style: 'currency',
					currency,
				}).format(cancellation_fee)
			} else return ''
		},
	},
	async beforeCreate() {
		try {
			const res = await axios.get('/v1/banks/')
			this.banks = res[0]
		} catch (err) {
			notification.error({
				message: 'Falha de conexão',
				description: 'Erro ao baixar algumas informações do servidor',
				duration: 6,
			})
		}
	},
	async beforeMount() {
		const res1 = this.getCompany(this.selectedMerchantId)
		const res2 = this.getContract(this.selectedMerchantId)

		this.company = await res1
		this.contract = await res2
	},
	methods: {
		...mapActions('merchants', ['getCompany', 'getContract']),
		formatDay(day) {
			return ('0' + day).slice(-2)
		},
	},
}
</script>

<style lang="less" scoped>
.descriptions {
	margin-top: 16px;
}

.doc-button {
	display: flex;
	justify-content: center;
	margin-top: 16px;
	height: 40px;
	padding: 10px;
	align-content: center;
	border: 1px solid #e8e8e8;
	border-radius: 5px;
	color: white;
	background-color: #e96d35;
	border-color: #e96d35;
	cursor: pointer;
	font-weight: 500;
	font-size: 12px;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	touch-action: manipulation;

	&:hover {
		background-color: #f57d31;
		border-color: #f57d31;
	}
}
</style>
